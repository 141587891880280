




















import { Component, Vue } from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";

@Component
export default class Login extends Vue {
  public email: string;
  public password: string;
  public settings: Settings;

  public constructor() {
    super();
    this.email = '';
    this.password = '';
    this.settings = new Settings();
  }

  public login(): void {
    this.axios.post(
        this.settings.settings.host + '/token', {
          email: this.email,
          password: this.password
        }
    ).then(response => {
      console.log(response);
      Auth.setToken(response.data.token);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('expiry', Math.round((new Date().getTime() / 1000) + 3600 * 24).toString());
      this.$router.push('/panel');
    }, error => {
      console.log(error);
      this.$notify({
        type: 'warn',
        group: 'notify',
        text: 'Error while login'
      });
    })
  }
}
